<template>
    <!-- 照明方案管理 -->
  <div>
    <div class="flex">
      <el-form :model="listQuery" :inline="true">
        <el-form-item>
          <el-input v-model="listQuery.name" placeholder="请输入设计师名称" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" @click="addClick">新增</el-button>
      </div>
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column type="index" label="序号" width=""></el-table-column>
      <el-table-column prop="name" label="设计师名称" width=""></el-table-column>
      <el-table-column prop="certificateName" label="设计师证书" width="" show-overflow-tooltip></el-table-column>
      <el-table-column prop="intro" label="设计理念" width="" show-overflow-tooltip></el-table-column>
      <el-table-column prop="date" label="操作" width="">
        <template v-slot="scope">
          <el-button type="text" @click="toppingClick(scope.row)" v-if="scope.$index !==0">置顶</el-button>
          <el-button type="text" @click="editClick(scope.row)">编辑</el-button>
          <el-button type="text" @click="viewClick(scope.row)">查看</el-button>
          <el-button type="text" class="red" @click="deleteClick(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="footer">
      <el-pagination
      @current-change="handleCurrentChange"
      :current-page="listQuery.current"
      :page-size="listQuery.size"
      layout="total, prev, pager, next, jumper"
      :total="listQuery.total">
      </el-pagination>
    </div>
  </div>
</template>
  
  <script>
  import {lightingpage,lightingDelete,lightingtop} from "../../api/index";
  export default {
  data() {
    return {
      listQuery:{
        name:"",
        size: 10,
        current: 1,
        total:0,
      },
      tableData:[],
    }
  },
  mounted() {
    this.getTableData()
  },
  methods:{
    getTableData() {
      lightingpage({...this.listQuery}).then(res=>{
        if(res.code==1) {
          this.tableData = res.data.records || [];
          this.listQuery.total = res.data.total || 0
        }
      })
    },
    addClick() {
      this.$router.push({name:'lightlingAdd'})
    },
    handleCurrentChange(current){
      this.listQuery.current = current;
      this.getTableData()
    },
    search() {
      this.listQuery.current = 1;
      this.getTableData()
    },
    reset() {
      this.listQuery = {
        name:"",
        size: 10,
        current: 1,
        total:0,
      }
      this.getTableData()
    },
    deleteClick(row) {
      this.$confirm("是否确认删除此方案？", "提示", {
        confirmButtonText: "确定",
        concelButtonText: "取消",
        type: "warning",
      }).then(() => {
        lightingDelete({lightingSchemeId:row.lightingSchemeId}).then((res) => {
          if (res.code == 1) {
            this.$message({
              type: "success",
              message: "删除成功！",
            });
            this.getTableData();
          }
        });
      });
    },
    editClick(row) {
      this.$router.push({
        name:'lightlingEdit',
        query:{
          lightingSchemeId:row.lightingSchemeId,
          isEdit:1,
        }
      })
    },
    viewClick(row) {
      this.$router.push({
        name:'lightlingView',
        query:{
          lightingSchemeId:row.lightingSchemeId,
          isView:true
        }
      })
    },
    // 置顶
    toppingClick(row) {
      lightingtop({lightingSchemeId: row.lightingSchemeId }).then(res=>{
        if(res.code==1) {
          this.$message.success('操作成功！');
          this.getTableData()
        }
      })
    }
  }
  }
  </script>
  
  <style lang="less" scoped>
  .flex {
  display: flex;
  justify-content: space-between;
  }
  .footer{
    margin-top: 20px;
    display: flex;
  justify-content: flex-end;
  }
  .red {
    color: red;
  }
  </style>